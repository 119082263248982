import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const ReconciliationApiService = {
  async generateReconciliationFile({ vendor, dates, invoice = null }) {
    try {
      return await HTTP.post(`reconciliation/${vendor}`, invoice, {
        responseType: 'arraybuffer',
        params: {
          from: dates[0],
          to: `${dates[1]} 23:59:59`,
        },
      });
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getVendors() {
    try {
      const { data } = await HTTP.get('reconciliation/vendors');

      return Promise.resolve(data || []);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
