<template>
  <vbt-content-box
    :loading="vendorsLoading"
    title="Reconciliation"
  >
    <v-layout align-center>
      <v-flex sm3>
        <vbt-date-picker
          v-model="dates"
          :clearable="false"
          label="Date range"
          confirm
          range
        />
      </v-flex>

      <v-flex
        class="px-4"
        sm3
      >
        <v-select
          v-model="vendor"
          :items="vendors"
          class="mt-3"
          label="Vendor"
        />
      </v-flex>

      <v-flex sm3>
        <v-file-input
          class="mt-3"
          label="File"
          @change="setInvoiceFile"
        />
      </v-flex>

      <v-btn
        color="success"
        class="mt-4 ml-10"
        small
        @click="generateReconciliationFile"
      >
        Generate
      </v-btn>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { wrapToLoadingFn, getDatesRangeByLastDays, downloadExcelWithDisposition } from '@helpers';

import { ReconciliationApiService } from '../_services/reconciliation.api.service';

export default {
  name: 'IrReconciliation',

  data() {
    return {
      file: null,
      vendor: '',
      vendors: [],
      invoiceFile: '',
      dates: getDatesRangeByLastDays(),

      vendorsLoading: false,
    };
  },

  created() {
    this.getVendors();
  },

  methods: {
    generateReconciliationFile() {
      const formData = new FormData();

      if (this.invoiceFile) {
        formData.append('invoice', this.invoiceFile, this.invoiceFile.name);
      }

      this.wrapToLoadingFn({
        req: ReconciliationApiService.generateReconciliationFile.bind({}, {
          invoice: this.invoiceFile ? formData : null,
          vendor: this.vendor,
          dates: this.dates,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: downloadExcelWithDisposition,
      });
    },

    getVendors() {
      this.wrapToLoadingFn({
        req: ReconciliationApiService.getVendors,
        loadingFlagName: 'vendorsLoading',
        onSuccess: (r) => {
          this.vendors = r;
          [this.vendor] = r;
        },
      });
    },

    setInvoiceFile(file) {
      this.invoiceFile = file;
    },

    wrapToLoadingFn,
  },
};
</script>
